function updateScroll(e) {
  const isNavSticky = window.pageYOffset > 150;
  const navEl = document.querySelector('.nif-site-navigation');

  if(isNavSticky) {
    navEl.classList.add('nif-site-navigation--active');
  } else {
    navEl.classList.remove('nif-site-navigation--active')
  }
}

function toggleNavigation(e) {
  const navEl = document.querySelector('.nif-site-navigation__nav');
  const btnEl = document.querySelector('.nif-site-navigation__show-more');
  const containerEl = document.querySelector('.nif-container');
  navEl.classList.toggle('nif-site-navigation__nav--open');
  btnEl.classList.toggle('nif-site-navigation__show-more--open');
  containerEl.classList.toggle('nif-container--overlay');
}

function toggleDropdownCard(e) {
  e.preventDefault();

  const toggleId = this.getAttribute('data-toggle');
  const target = document.querySelector(`.nif-dropdown-card-content[data-id="${toggleId}"]`);

  // If already active, just close
  if(!target.classList.contains('nif-dropdown-card-content--active')) {
    // Remove all active styles from others
    const arrDropdownCardsContent = document.querySelectorAll('.nif-dropdown-card-content');
    [].forEach.call(arrDropdownCardsContent, function(card) {
      card.classList.remove('nif-dropdown-card-content--active');
    })

    const arrDropdownCards = document.querySelectorAll('.nif-dropdown-card');
    [].forEach.call(arrDropdownCards, function(card) {
      card.classList.remove('nif-dropdown-card--active');
    })
  }



  // Open active one
  if(target) {
    target.classList.toggle('nif-dropdown-card-content--active');
    this.classList.toggle('nif-dropdown-card--active');
  }
  return false;
}

function toggleCollapsibleContent(e) {
  e.preventDefault();
  const toggleId = this.getAttribute('data-toggle');
  const target = document.querySelector(`.nif-collapsible-content__content[data-id="${toggleId}"]`);
  if(target) {
    target.classList.toggle('nif-collapsible-content__content--active');
    this.classList.toggle('nif-collapsible-content__toggle--active');
  }
  return false;
}

document.onreadystatechange = () => {
  if (document.readyState === 'complete') {

    // Menu
    window.addEventListener('scroll', updateScroll);
    updateScroll();

    // Burger menu
    document.querySelector('.nif-site-navigation__show-more').addEventListener('click', toggleNavigation);

    // Dropdowns
    const dropdownCards = document.querySelectorAll('.js-nif-dropdown-card');
    [].forEach.call(dropdownCards, function(card) {
      card.addEventListener('click', toggleDropdownCard);
    })

    // CollapsibleContent
    const collapsibleContents = document.querySelectorAll('.js-nif-collapsible-content-toggle');
    [].forEach.call(collapsibleContents, function(cc) {
      cc.addEventListener('click', toggleCollapsibleContent);
    })
  }
};
